<template>
  <el-form
      :size="size"
      label-position="left"
      label-width="150px"
      ref="form"
  >
    <el-form-item :label="$t('system.start_date')">
      <el-date-picker
          :class="{'validate-error': requiredFields.includes('startDate')}"
          format="yyyy-MM-dd"
          v-model="startDate"
          value-format="yyyy-MM-dd"
      />
    </el-form-item>
    <el-form-item :label="$t('system.repeat_every')">
      <div class="d-flex justify-content-between">
        <el-input-number
            :max="99"
            :min="1"
            controls-position="right"
            style="width: 80px"
            type="number"
            v-model="repeatValue"
        />
        <el-select
            filterable
            style="max-width: 200px"
            v-model="repeatType"
        >
          <el-option
              :key="type.key"
              :label="$t(type.label)"
              :value="type.key"
              v-for="type in repeatTypes"
          />
        </el-select>
      </div>
    </el-form-item>
    <el-form-item
        :label="$t('system.select_days')"
        v-if="repeatType !== 'daily'"
    >
      <template v-if="repeatType === 'weekly'">
        <el-tag
            :effect="day.isSelected ? 'dark' : 'plain'"
            :key="day.id"
            @click.stop="toggleActiveDay(day, 'weekly')"
            class="pointer mr-1 text-center text-selection-none"
            size="mini"
            style="min-width: 26px"
            type="success"
            v-for="day in daysOfWeek"
        >
          {{ $t(day.label) }}
        </el-tag>
      </template>
      <template v-else-if="repeatType === 'monthly'">
        <el-tag
            :effect="day.isSelected ? 'dark' : 'plain'"
            :key="day.id"
            @click.stop="toggleActiveDay(day, 'monthly')"
            class="pointer mr-1 text-center text-selection-none"
            size="mini"
            style="min-width: 26px"
            type="success"
            v-for="day in daysOfMonth"
        >
          {{ $t(day.id) }}
        </el-tag>
      </template>
      <template v-else-if="repeatType === 'yearly'">
        <div class="d-flex">
          <el-select
              :placeholder="$t('system.month')"
              class="mr-1"
              filterable
              v-model="yearlyRepeatMonth"
          >
            <el-option
                :key="idx"
                :label="$t(`system.${month.toLowerCase()}`)"
                :value="idx + 1"
                v-for="(month, idx) in months"
            />
          </el-select>
          <el-select
              :disabled="!yearlyRepeatMonth"
              :placeholder="$t('system.day')"
              filterable
              style="max-width: 120px"
              v-model="yearlyRepeatDay"
          >
            <el-option
                :key="day"
                :label="day"
                :value="day"
                v-for="day in daysInMonth"
            />
          </el-select>
        </div>
      </template>
    </el-form-item>
        <el-form-item :label="$t('system.finish')">
          <el-date-picker
            v-model="finishDate"
            style="max-width: 200px"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            :disabled="!startDate"
            :picker-options="finishDateOptions"
          />
        </el-form-item>
    <el-form-item class="d-flex justify-content-end mb-0">
      <el-button
          :loading="saveLoading"
          @click="save"
          type="success"
      >
        {{ $t('system.save') }}
      </el-button>
      <el-button @click="close">{{ $t('system.cancel') }}</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import Vue from 'vue';
import {getDaysInMonth} from '@/helpers/functions';
import moment from 'moment';

export default {

  name: 'RecurringCalendar',
  props: {
    saveLoading: {
      type: Boolean,
    },
  },
  data() {
    return {
      size: 'mini',
      months: ['January', 'February', 'March', 'April', 'May',
        'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      repeatTypes: [
        {
          key: 'daily',
          label: 'system.day',
        },
        {
          key: 'weekly',
          label: 'system.week',
        },
        {
          key: 'monthly',
          label: 'system.month',
        },
        {
          key: 'yearly',
          label: 'system.year',
        },
      ],
      repeatValue: 1,
      repeatType: 'daily',
      daysOfWeek: [
        {
          id: 0,
          label: 'system.monday',
        },
        {
          id: 1,
          label: 'system.tuesday',
        },
        {
          id: 2,
          label: 'system.wednesday',
        },
        {
          id: 3,
          label: 'system.thursday',
        },
        {
          id: 4,
          label: 'system.friday',
        },
        {
          id: 5,
          label: 'system.saturday',
        },
        {
          id: 6,
          label: 'system.sunday',
        },
      ],
      daysOfMonth: [],
      finishType: 'never',
      finishDate: null,
      timesToFinish: 1,
      startDate: null,
      yearlyRepeatMonthDays: [],
      yearlyRepeatMonth: null,
      yearlyRepeatDay: null,
      requiredFields: [],
      finishDateOptions: {
        firstDayOfWeek: 1,
        disabledDate: date => date.getTime() <= moment(this.startDate)
      }
    }
  },
  created() {
    for (let i = 1; i <= 28; i++) {
      this.daysOfMonth.push({id: i});
    }
    this.daysOfMonth.push({id: 'last'});
  },
  watch: {
    yearlyRepeatMonth(newVal) {
      if (this.yearlyRepeatDay > getDaysInMonth(newVal, 2019)) {
        this.yearlyRepeatDay = null;
      }
    },
  },
  computed: {
    daysInMonth() {
      return getDaysInMonth(this.yearlyRepeatMonth, 2019);
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    save() {
      if (!this.startDate) {
        this.requiredFields = [...this.requiredFields, 'startDate'];
        return;
      } else {
        this.requiredFields = [];
      }
      const data = {
        startDate: this.startDate,
        repeatValue: this.repeatValue,
        repeatType: this.repeatType,
        finishDate: this.finishDate
      };
      if (this.repeatType === 'weekly') {
        data.days = this.daysOfWeek.filter(item => item.isSelected).map(item => item.id);
      } else if (this.repeatType === 'monthly') {
        data.days = this.daysOfMonth.filter(item => item.isSelected).map(item => item.id);
      } else if (this.repeatType === 'yearly') {
        data.month = this.yearlyRepeatMonth;
        data.day = this.yearlyRepeatDay;
      }
      this.$emit('save', data);
    },
    toggleActiveDay(day, type) {
      let dataProperty = '';
      switch (type) {
        case 'monthly':
          dataProperty = 'daysOfMonth';
          break;
        case 'weekly':
          dataProperty = 'daysOfWeek';
          break;
      }
      const candidateIdx = this[dataProperty].findIndex(item => item.id === day.id);
      if (candidateIdx >= 0) {
        Vue.set(this[dataProperty][candidateIdx], 'isSelected', !this[dataProperty][candidateIdx].isSelected);
      }
    },
  },
};
</script>

<style scoped>

</style>
